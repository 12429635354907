<template>
  <tr v-if="data">
    <td class="c-table__grouped-cells">
      <div class="c-table__cell-content">
        {{$t('import.group.' + section)}}
      </div>
    </td>
    <td class="c-table__grouped-cells is-first">
      <div class="c-table__cell-content">
        {{ data.counts.create ? data.counts.create : '-'}}
      </div>
    </td>
    <td class="c-table__grouped-cells">
      <div class="c-table__cell-content">
        {{ data.counts.update ? data.counts.update : '-'}}
      </div>
    </td>
    <td class="c-table__grouped-cells is-first">
      <div class="c-table__cell-content">
        {{ data.counts.finished ? data.counts.finished : '-'}}
      </div>
    </td>
    <td class="c-table__grouped-cells">
      <div class="c-table__cell-content">
        {{ data.counts.error ? data.counts.error : '-'}}
      </div>
    </td>
    <td class="c-table__grouped-cells is-first">
      <div class="c-table__cell-content">
        <router-link exact :to="{ name: 'admin.event.discipline.import.view.detail', params: {importType: data.section}}">
          {{ $t('import.details.view') }}
        </router-link>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "viewSummarySection",
  props: ['section'],
  computed: {
    data: function() {
      return this.$store.state.imports.importData.find(d => d.section === this.section)
    },
  },

}
</script>
