<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('import.add') }} {{ $t('import.add.fromSceEvent') }}</h2>
    <div class="c-main">
      <form class="c-main--flex-1">
        <div class="o-layout">
          <div class="o-layout__item">
            <div class="c-input">
              <label for="event" class="c-input__label">
                {{ $t('event') }}
              </label>
              <select class="c-input__field c-input__field-select" v-model="eventId"
                      id="event" @change="fetchEventDisciplines()">
                <option v-for="event in events" :value="event.id" :key="event.id">
                  {{ event.title }} ({{ event.date_from.substring(0, 10) }})
                </option>
              </select>
            </div>
          </div>
          <div class="o-layout__item">
            <div class="c-input">
              <label for="eventDiscipline" class="c-input__label">
                {{ $t('eventDiscipline') }}
              </label>
              <select class="c-input__field c-input__field-select" v-model="eventDisciplineId"
                      id="eventDiscipline">
                <option v-for="eventDiscipline in eventDisciplines" :value="eventDiscipline.id"
                        :key="eventDiscipline.id">
                  {{ eventDiscipline.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <fields v-if="ready" v-model="selection" :assignFields="false" :discipline="discipline"
                :sections="$options.fieldSections"/>
      </form>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.event.discipline.import'}">
          <button class="c-button c-button--ghost c-button--large">{{ $t("cancel") }}</button>
        </router-link>
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()"
                :class="{'c-button--disabled': ! selection.valid}">
          {{ $t('continue') }}
        </button>
      </div>

    </footer>
  </div>
</template>

<script>
import forOwn from 'lodash/forOwn'
import sortBy from 'lodash/sortBy'

import config from 'client/config'

export default {
  name: "import-add-event",
  components: {
    fields: require('./fields.vue').default,
  },
  fieldSections: [
    'club',
    'member',
    'group',
    'round',
    'category',
    'team',
    'participation',
    'teamParticipation',
    'session',
    'order',
    'exercise',
  ],
  data: function () {
    return {
      eventId: undefined,
      eventDisciplines: [],
      eventDisciplineId: undefined,
      data: undefined,
      ready: false,
      sending: false,
      importData: [],
      selection: {
        fields: {},
        valid: false,
      },
    };
  },
  computed: {
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
    events: function () {
      return sortBy(this.$store.state.events.items, 'date_from').reverse()
    }
  },
  methods: {
    fetchEventDisciplines: function () {
      this.eventDisciplines = [];
      this.eventDisciplineId = undefined;

      if (this.eventId) {
        this.$http.get(config.root + '/events/' + this.eventId + '/eventDisciplines').then((result) => {
          this.eventDisciplines = result.data;
          if (result.data.length) {
            this.eventDisciplineId = result.data[0].id;
            this.ready = true;
          }
        });
      }
    },
    getSections: function () {
      let sections = []
      forOwn(this.selection.fields, function (group, group_key) {
        if (!group.enabled || !group.import) {
          return
        }

        sections.push(group_key)
      });
      return sections
    },

    submit: function () {
      const data = {
        type: 'event',
        sections: this.getSections(),
      };

      data.sourceEventId = this.eventId;
      data.sourceEventDisciplineId = this.eventDisciplineId;

      this.$store.dispatch('import.add', data).then((result) => {
        this.$navigation.goto({name: 'admin.event.discipline.import.view', params: {importId: result.id}});
      });
    },
  }
};
</script>
